import React, { useState } from 'react';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Link,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = ({ setError, setSuccess }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState(''); // Muss zusammengeschrieben sein
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/g.test(password);
    return minLength && specialChar;
  };

  const handleRegister = () => {
    if (!validatePassword(password)) {
      setError('Passwort muss mindestens 8 Zeichen und ein Sonderzeichen enthalten');
      return;
    }

    axios
      .post('https://time.nohello.biz/api/register', {
        firstName,
        lastName,
        username,
        password,
      })
      .then(() => {
        setSuccess('Registrierung erfolgreich. Bitte melden Sie sich an.');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Fehler bei der Registrierung:', error);
        setError('Registrierung fehlgeschlagen');
      });
  };

  return (
    <Box display="flex" justifyContent="center" mt={8}>
      <Paper sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Registrierung
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Vorname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Nachname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value.replace(/\s+/g, ''))}
            fullWidth
            required
            helperText="Muss zusammengeschrieben sein"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Passwort"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            helperText="Mindestens 8 Zeichen und 1 Sonderzeichen"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleRegister}
          disabled={!firstName || !lastName || !username || !password}
        >
          Registrieren
        </Button>
        <Box mt={2}>
          <Typography variant="body2">
            Bereits einen Account?{' '}
            <Link href="/login" onClick={() => navigate('/login')}>
              Anmelden
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Register;
