// AdminPanel.js
import React, { useState, useEffect, useContext } from 'react';
import {
  Paper,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Switch,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EditUserHours from './EditUserHours';
import EditUserLogs from './EditUserLogs';

const AdminPanel = ({ setError, setSuccess }) => {
  const [users, setUsers] = useState([]);
  const { user } = useContext(UserContext);
  const [editUser, setEditUser] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [selectedUserLogs, setSelectedUserLogs] = useState([]);
  const [editLog, setEditLog] = useState(null);
  const [openEditLogDialog, setOpenEditLogDialog] = useState(false);
  const [editHoursUserId, setEditHoursUserId] = useState(null);
  const [currentHours, setCurrentHours] = useState(0);
  const navigate = useNavigate();

  const fetchUsers = () => {
    axios
      .get('https://time.nohello.biz/api/admin/users', {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Benutzer:', error);
        setError('Fehler beim Abrufen der Benutzer');
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

const handleDeleteUser = (userId) => {
  axios
    .delete(`https://time.nohello.biz/api/admin/users/${userId}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
    .then(() => {
      setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
      setSuccess('Benutzer erfolgreich gelöscht');
    })
    .catch((error) => {
      console.error('Fehler beim Löschen des Benutzers:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(`Fehler beim Löschen des Benutzers: ${error.response.data.error}`);
      } else {
        setError('Fehler beim Löschen des Benutzers');
      }
    });
};

  const handleToggleAdmin = (userId, isAdmin) => {
    const adminStatus = isAdmin ? 1 : 0;
    const userToUpdate = users.find((u) => u.id === userId);
    if (!userToUpdate) {
      setError('Benutzer nicht gefunden');
      return;
    }

    const updatedData = {
      firstName: userToUpdate.firstName,
      lastName: userToUpdate.lastName,
      username: userToUpdate.username,
      isAdmin: adminStatus,
      isLocked: userToUpdate.isLocked || 0,
    };

    axios
      .put(`https://time.nohello.biz/api/admin/users/${userId}`, updatedData, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === userId ? { ...u, isAdmin: adminStatus } : u))
        );
        setSuccess('Benutzerrechte erfolgreich aktualisiert');
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren des Benutzers:', error);
        setError('Fehler beim Aktualisieren des Benutzers');
      });
  };

  const handleLockUser = (userId, isLocked) => {
    axios
      .put(`https://time.nohello.biz/api/admin/users/${userId}/lock`, { isLocked }, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === userId ? { ...u, isLocked: isLocked ? 1 : 0 } : u))
        );
        setSuccess(`Benutzer ${isLocked ? 'gesperrt' : 'entsperrt'}`);
      })
      .catch((error) => {
        console.error('Fehler beim Sperren/Entsperren des Benutzers:', error);
        setError('Fehler beim Sperren/Entsperren des Benutzers');
      });
  };

  const handleEditUser = (user) => {
    setEditUser(user);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditUser(null);
  };

  const handleSaveUser = () => {
    if (!editUser.firstName || !editUser.lastName || !editUser.username) {
      setError('Vorname, Nachname und Benutzername sind erforderlich');
      return;
    }

    const updatedData = {
      firstName: editUser.firstName,
      lastName: editUser.lastName,
      username: editUser.username,
      isAdmin: editUser.isAdmin,
      isLocked: editUser.isLocked || 0,
    };

    if (editUser.password) {
      updatedData.password = editUser.password;
    }

    axios
      .put(`https://time.nohello.biz/api/admin/users/${editUser.id}`, updatedData, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === editUser.id ? editUser : u))
        );
        setSuccess('Benutzerdaten erfolgreich aktualisiert');
        handleCloseEditDialog();
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren des Benutzers:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setError(`Fehler beim Aktualisieren des Benutzers: ${error.response.data.error}`);
        } else {
          setError('Fehler beim Aktualisieren des Benutzers');
        }
      });
  };

  const handleEditHours = (userId, currentHours) => {
    setEditHoursUserId(userId);
    setCurrentHours(currentHours);
  };

  const handleCloseEditHours = () => {
    setEditHoursUserId(null);
    setCurrentHours(0);
  };

  const handleUpdateHours = () => {
    if (currentHours < 0) {
      setError('Stunden dürfen nicht negativ sein');
      return;
    }

    axios
      .put(`https://time.nohello.biz/api/admin/users/${editHoursUserId}/hours`, { hours: currentHours }, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === editHoursUserId ? { ...u, hours: currentHours } : u))
        );
        setSuccess('Stunden erfolgreich aktualisiert');
        handleCloseEditHours();
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Stunden:', error);
        setError('Fehler beim Aktualisieren der Stunden');
      });
  };

  const handleViewLogs = (userId) => {
    axios
      .get(`https://time.nohello.biz/api/admin/users/${userId}/logs`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setSelectedUserLogs(response.data);
        setOpenLogsDialog(true);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Logs:', error);
        setError('Fehler beim Abrufen der Logs');
      });
  };

  const handleEditLog = (log) => {
    setEditLog(log);
    setOpenEditLogDialog(true);
  };

  const handleCloseLogsDialog = () => {
    setOpenLogsDialog(false);
    setSelectedUserLogs([]);
  };

  const handleCloseEditLogDialog = () => {
    setOpenEditLogDialog(false);
    setEditLog(null);
  };

  const handleSaveLog = () => {
    const { id, user_id, date, start_time, end_time, duration, balance, schoolday } = editLog;

    axios
      .put(`https://time.nohello.biz/api/admin/users/${user_id}/logs/${id}`, {
        date,
        start_time,
        end_time,
        duration,
        balance,
        schoolday,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setSelectedUserLogs((prevLogs) =>
          prevLogs.map((log) => (log.id === id ? editLog : log))
        );
        setSuccess('Log erfolgreich aktualisiert');
        handleCloseEditLogDialog();
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren des Logs:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setError(`Fehler beim Aktualisieren des Logs: ${error.response.data.error}`);
        } else {
          setError('Fehler beim Aktualisieren des Logs');
        }
      });
  };

  const handleBackToDashboard = () => {
    navigate('/'); // Navigiert zurück zum Dashboard
  };

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <Paper sx={{ p: 4, width: '100%', maxWidth: 1200 }}>
        <Typography variant="h5" gutterBottom>
          Admin Panel - Benutzerverwaltung
        </Typography>

        {/* Zurück zum Dashboard Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBackToDashboard}
          sx={{ mb: 2 }}
        >
          Zurück zum Dashboard
        </Button>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Vorname</TableCell>
                <TableCell>Nachname</TableCell>
                <TableCell>Benutzername</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>Sperren</TableCell>
                <TableCell>Stunden</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((u) => (
                <TableRow key={u.id}>
                  <TableCell>{u.firstName}</TableCell>
                  <TableCell>{u.lastName}</TableCell>
                  <TableCell>{u.username}</TableCell>
                  <TableCell>
                    <Switch
                      checked={u.isAdmin === 1}
                      onChange={(e) => handleToggleAdmin(u.id, e.target.checked)}
                      color="primary"
                      disabled={u.id === user.id}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color={u.isLocked ? "secondary" : "primary"}
                      onClick={() => handleLockUser(u.id, !u.isLocked)}
                      disabled={u.id === user.id}
                    >
                      {u.isLocked ? <UnlockIcon /> : <LockIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="warning"
                      onClick={() => handleEditHours(u.id, u.hours)}
                      disabled={u.id === user.id}
                    >
                      <Typography variant="body2">Stunden</Typography>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditUser(u)}
                      disabled={u.id === user.id}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteUser(u.id)}
                      disabled={u.id === user.id}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="info"
                      onClick={() => handleViewLogs(u.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog zum Bearbeiten eines Benutzers */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Benutzer bearbeiten</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Vorname"
                value={editUser?.firstName || ''}
                onChange={(e) =>
                  setEditUser({ ...editUser, firstName: e.target.value })
                }
                fullWidth
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Nachname"
                value={editUser?.lastName || ''}
                onChange={(e) =>
                  setEditUser({ ...editUser, lastName: e.target.value })
                }
                fullWidth
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Benutzername"
                value={editUser?.username || ''}
                onChange={(e) =>
                  setEditUser({ ...editUser, username: e.target.value.replace(/\s+/g, '') })
                }
                fullWidth
                required
                helperText="Muss zusammengeschrieben sein"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Passwort"
                type="password"
                value={editUser?.password || ''}
                onChange={(e) =>
                  setEditUser({ ...editUser, password: e.target.value })
                }
                fullWidth
                helperText="Leer lassen, um das Passwort nicht zu ändern"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog}>Abbrechen</Button>
            <Button onClick={handleSaveUser} variant="contained" color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog zum Anzeigen von Logs */}
        <Dialog open={openLogsDialog} onClose={handleCloseLogsDialog} maxWidth="md" fullWidth>
          <DialogTitle>Logs einsehen und bearbeiten</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Startzeit</TableCell>
                    <TableCell>Endzeit</TableCell>
                    <TableCell>Dauer</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Schultag</TableCell>
                    <TableCell>Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUserLogs.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>{log.date}</TableCell>
                      <TableCell>{log.start_time}</TableCell>
                      <TableCell>{log.end_time}</TableCell>
                      <TableCell>{log.duration}</TableCell>
                      <TableCell>{log.balance}</TableCell>
                      <TableCell>{log.schoolday ? 'Ja' : 'Nein'}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditLog(log)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLogsDialog}>Schließen</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog zum Bearbeiten eines Logs */}
        <Dialog open={openEditLogDialog} onClose={handleCloseEditLogDialog}>
          <DialogTitle>Log bearbeiten</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Datum"
                type="date"
                value={editLog?.date || ''}
                onChange={(e) =>
                  setEditLog({ ...editLog, date: e.target.value })
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Startzeit"
                type="time"
                value={editLog?.start_time || ''}
                onChange={(e) =>
                  setEditLog({ ...editLog, start_time: e.target.value })
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Endzeit"
                type="time"
                value={editLog?.end_time || ''}
                onChange={(e) =>
                  setEditLog({ ...editLog, end_time: e.target.value })
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Dauer (Stunden)"
                type="number"
                value={editLog?.duration || ''}
                onChange={(e) =>
                  setEditLog({ ...editLog, duration: parseFloat(e.target.value) })
                }
                fullWidth
                required
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Balance (Stunden)"
                type="number"
                value={editLog?.balance || ''}
                onChange={(e) =>
                  setEditLog({ ...editLog, balance: parseFloat(e.target.value) })
                }
                fullWidth
                required
              />
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={editLog?.schoolday || false}
                onChange={(e) =>
                  setEditLog({ ...editLog, schoolday: e.target.checked })
                }
                color="primary"
              />
              <Typography component="span">Schultag</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditLogDialog}>Abbrechen</Button>
            <Button onClick={handleSaveLog} variant="contained" color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog zum Bearbeiten von Stunden */}
        <EditUserHours
          open={!!editHoursUserId}
          handleClose={handleCloseEditHours}
          userId={editHoursUserId}
          currentHours={currentHours}
          setError={setError}
          setSuccess={setSuccess}
          refreshUsers={fetchUsers}
        />

        {/* Dialog zum Bearbeiten von Logs */}
        <EditUserLogs
          open={openEditLogDialog}
          handleClose={handleCloseEditLogDialog}
          log={editLog}
          setError={setError}
          setSuccess={setSuccess}
          refreshLogs={() => handleViewLogs(editLog?.user_id)}
        />
      </Paper>
    </Box>
  );
};

export default AdminPanel;
