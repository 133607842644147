import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  AppBar,
  Toolbar,
  Button,
  Snackbar,
  Alert,
  CssBaseline,
} from '@mui/material';
import axios from 'axios';
import TimerComponent from './components/TimerComponent';
import LogsTable from './components/LogsTable';
import UserPanel from './components/UserPanel';
import BalancePanel from './components/BalancePanel';
import CurrentTimePanel from './components/CurrentTimePanel';
import Login from './components/Login';
import Register from './components/Register';
import AdminPanel from './components/AdminPanel';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';
import './App.css';

function App() {
  const [logs, setLogs] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  const fetchLogs = useCallback(() => {
    if (!user) return;

    axios
      .get('https://time.nohello.biz/api/logs', {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setLogs(response.data);
        const balanceSum = response.data.reduce(
          (sum, log) => sum + parseFloat(log.balance),
          0
        );
        setTotalBalance(balanceSum);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Logs:', error);
        setError('Fehler beim Abrufen der Logs');
      });
  }, [user]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  const handleDeleteLog = useCallback(
    (id) => {
      axios
        .delete(`https://time.nohello.biz/api/logs/${id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then(() => {
          setLogs((prevLogs) => prevLogs.filter((log) => log.id !== id));
          const deletedLog = logs.find((log) => log.id === id);
          if (deletedLog) {
            setTotalBalance((prevBalance) => prevBalance - parseFloat(deletedLog.balance));
          }
          setSuccess('Log erfolgreich gelöscht');
        })
        .catch((error) => {
          console.error('Fehler beim Löschen des Logs:', error);
          setError('Fehler beim Löschen des Logs');
        });
    },
    [logs, user]
  );

  const handleCloseSnackbar = () => {
    setError(null);
    setSuccess(null);
  };

  const isAuthenticated = () => !!user;

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <CssBaseline />
          {/* Fixed AppBar */}
          <AppBar position="fixed" color="primary">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Arbeitszeit Tracker Dashboard
              </Typography>
              {isAuthenticated() && (
                <>
                  {user.isAdmin === 1 && (
                    <Button color="inherit" component={Link} to="/admin">
                      Admin Panel
                    </Button>
                  )}
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>

          {/* Spacer Toolbar to offset the fixed AppBar */}
          <Toolbar />

          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Routes>
              <Route
                path="/login"
                element={
                  isAuthenticated() ? (
                    <Navigate to="/" />
                  ) : (
                    <Login setError={setError} />
                  )
                }
              />
              <Route
                path="/register"
                element={
                  isAuthenticated() ? (
                    <Navigate to="/" />
                  ) : (
                    <Register setError={setError} setSuccess={setSuccess} />
                  )
                }
              />
              <Route
                path="/admin"
                element={
                  isAuthenticated() && user.isAdmin === 1 ? (
                    <AdminPanel setError={setError} setSuccess={setSuccess} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/"
                element={
                  isAuthenticated() ? (
                    <Box sx={{ flexGrow: 1 }}>
                      {/* Benutzer-, Stundenkonto- und Uhrzeit-Panels */}
                      <Container maxWidth="lg" sx={{ mt:4, mb: 4 }}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={4}>
                            <UserPanel userName={`${user.firstName} ${user.lastName}`} />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <BalancePanel totalBalance={totalBalance} />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CurrentTimePanel currentTime={currentTime} />
                          </Grid>
                        </Grid>
                      </Container>

                      {/* Timer und Logs */}
                      <Container maxWidth="lg" sx={{ flexGrow: 1, mb: 4 }}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <TimerComponent
                              onFetchLogs={fetchLogs}
                              setError={setError}
                              setSuccess={setSuccess}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LogsTable logs={logs} onDeleteLog={handleDeleteLog} />
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            </Routes>

            {/* Snackbar für Fehler- und Erfolgsmeldungen */}
            <Snackbar
              open={!!error || !!success}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              {error ? (
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                  {error}
                </Alert>
              ) : (
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                  {success}
                </Alert>
              )}
            </Snackbar>
          </Box>
        </Box>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
