import React, { useState, useContext } from 'react';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Link,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const Login = ({ setError }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogin = () => {
    axios
      .post('https://time.nohello.biz/api/login', { username, password })
      .then((response) => {
        setUser(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/');
      })
      .catch((error) => {
        console.error('Fehler beim Login:', error);
        setError('Ungültige Anmeldedaten');
      });
  };

  return (
    <Box display="flex" justifyContent="center" mt={8}>
      <Paper sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Anmeldung
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Passwort"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={!username || !password}
        >
          Anmelden
        </Button>
        <Box mt={2}>
          <Typography variant="body2">
            Noch keinen Account?{' '}
            <Link href="/register" onClick={() => navigate('/register')}>
              Registrieren
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
