import React from 'react';
import { Paper, Typography } from '@mui/material';

const BalancePanel = ({ totalBalance }) => (
  <Paper
    sx={{
      p: 2,
      textAlign: 'center',
      backgroundColor: totalBalance >= 0 ? '#e8f5e9' : '#ffebee',
      borderRadius: 2,
      height: '150px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    elevation={3}
  >
    <Typography variant="h6">Stundenkonto</Typography>
    <Typography variant="h4" sx={{ color: totalBalance >= 0 ? 'green' : 'red' }}>
      {totalBalance >= 0
        ? `+${totalBalance.toFixed(2)} Stunden`
        : `${totalBalance.toFixed(2)} Stunden`}
    </Typography>
  </Paper>
);

export default BalancePanel;
