import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { format } from 'date-fns';

const CurrentTimePanel = ({ currentTime }) => {
  const formattedTime = useMemo(() => format(currentTime, 'HH:mm:ss'), [currentTime]);

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={3}
    >
      <Typography variant="h6">Aktuelle Uhrzeit</Typography>
      <Typography variant="h4">{formattedTime}</Typography>
    </Paper>
  );
};

export default CurrentTimePanel;
