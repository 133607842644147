import React, { useState, useMemo } from 'react';
import {
  Paper,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Pagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SchoolIcon from '@mui/icons-material/School';

const LogsTable = ({ logs, onDeleteLog }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10;

  const formatLogDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const dayName = days[date.getDay()];
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${dayName} (${day}.${month})`;
  };

  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : '';
  };

  const formatBalance = (balance) => {
    return `${balance >= 0 ? '+' : ''}${balance.toFixed(2)}`;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredLogs = useMemo(() => {
    if (!searchQuery) return logs;
    return logs.filter((log) => {
      const dateMatch = log.date.includes(searchQuery);
      const durationMatch = log.duration.includes(searchQuery);
      const dayMatch = formatLogDate(log.date).toLowerCase().includes(searchQuery.toLowerCase());
      return dateMatch || durationMatch || dayMatch;
    });
  }, [logs, searchQuery]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Paper sx={{ p: 2, flexGrow: 1, overflow: 'hidden' }}>
      <Typography variant="h5" gutterBottom>
        Alle Logs
      </Typography>

      <Box sx={{ mb: 2 }}>
        <TextField
          label="Suche nach Datum, Dauer oder Tag"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Box>

      <TableContainer sx={{ maxHeight: 600 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>Ende</TableCell>
              <TableCell>Dauer</TableCell>
              <TableCell>Saldo</TableCell>
              <TableCell>Schule</TableCell>
              <TableCell>Löschen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLogs.map((log) => (
              <TableRow key={log.id}>
                <TableCell>{formatLogDate(log.date)}</TableCell>
                <TableCell>{formatTime(log.start_time)}</TableCell>
                <TableCell>{formatTime(log.end_time)}</TableCell>
                <TableCell>{log.duration}</TableCell>
                <TableCell sx={{ color: log.balance >= 0 ? 'green' : 'red' }}>
                  {formatBalance(parseFloat(log.balance))} h
                </TableCell>
                <TableCell>
                  {log.schoolday ? (
                    <SchoolIcon color="primary" />
                  ) : (
                    <Typography variant="body2">Nein</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => onDeleteLog(log.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Paper>
  );
};

export default LogsTable;
