// EditUserHours.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';

const EditUserHours = ({ open, handleClose, userId, currentHours, setError, setSuccess, refreshUsers }) => {
  const [hours, setHours] = useState(currentHours);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setHours(currentHours);
  }, [currentHours]);

  const handleSave = () => {
    if (isNaN(hours)) {
      setError('Bitte eine gültige Zahl eingeben');
      return;
    }
    if (hours < 0) {
      setError('Stunden dürfen nicht negativ sein');
      return;
    }

    axios
      .put(`https://time.nohello.biz/api/admin/users/${userId}/hours`, { hours }, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setSuccess('Stunden erfolgreich aktualisiert');
        handleClose();
        refreshUsers();
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Stunden:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setError(`Fehler beim Aktualisieren der Stunden: ${error.response.data.error}`);
        } else {
          setError('Fehler beim Aktualisieren der Stunden');
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Stunden bearbeiten</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Stunden"
            type="number"
            value={hours}
            onChange={(e) => setHours(parseFloat(e.target.value))}
            fullWidth
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserHours;
