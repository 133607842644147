import React, { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import { UserContext } from '../contexts/UserContext';

const UserPanel = () => {
  const { user } = useContext(UserContext);

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={3}
    >
      <Typography variant="h6">Benutzer</Typography>
      <Typography variant="h4">{`${user.firstName} ${user.lastName}`}</Typography>
    </Paper>
  );
};

export default UserPanel;
