import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Paper,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { UserContext } from '../contexts/UserContext';

const TimerComponent = ({ onFetchLogs, setError, setSuccess }) => {
  const [startTime, setStartTime] = useState(localStorage.getItem('startTime') || '');
  const [isTracking, setIsTracking] = useState(!!localStorage.getItem('startTime'));
  const [workDuration, setWorkDuration] = useState('00:00:00');
  const [balance, setBalance] = useState(0);
  const [schoolday, setSchoolday] = useState(false);
  const [isPaused, setIsPaused] = useState(localStorage.getItem('isPaused') === 'true');
  const [pauseStartTime, setPauseStartTime] = useState(
    localStorage.getItem('pauseStartTime') ? parseInt(localStorage.getItem('pauseStartTime')) : null
  );
  const [pauseTimeLeft, setPauseTimeLeft] = useState(0);
  const [workedSeconds, setWorkedSeconds] = useState(0);
  const [pauseCompleted, setPauseCompleted] = useState(
    localStorage.getItem('pauseCompleted') === 'true'
  );
  const [startTimestamp, setStartTimestamp] = useState(
    localStorage.getItem('startTimestamp') ? parseInt(localStorage.getItem('startTimestamp')) : null
  );
  const { user } = useContext(UserContext);

  const updateWorkedSeconds = useCallback(() => {
    if (startTimestamp) {
      const now = Date.now();
      const totalWorkedSeconds = Math.floor((now - startTimestamp) / 1000);

      let actualWorkedSeconds = totalWorkedSeconds;

      if (isPaused && pauseStartTime) {
        actualWorkedSeconds = Math.floor((pauseStartTime - startTimestamp) / 1000);
      } else if (pauseCompleted) {
        actualWorkedSeconds = totalWorkedSeconds - 30 * 60;
      }

      setWorkedSeconds(actualWorkedSeconds);

      const hrs = Math.floor(actualWorkedSeconds / 3600)
        .toString()
        .padStart(2, '0');
      const mins = Math.floor((actualWorkedSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const secs = (actualWorkedSeconds % 60).toString().padStart(2, '0');

      setWorkDuration(`${hrs}:${mins}:${secs}`);

      const fixedHours = 7.8;
      const workedHours = actualWorkedSeconds / 3600;
      const currentBalance = workedHours - fixedHours;

      setBalance(currentBalance);
    }
  }, [startTimestamp, isPaused, pauseStartTime, pauseCompleted]);

  useEffect(() => {
    let interval = null;

    if (isTracking && startTime) {
      let start = startTimestamp ? new Date(startTimestamp) : new Date();

      if (!startTimestamp) {
        const [hours, minutes] = startTime.split(':');
        start.setHours(hours);
        start.setMinutes(minutes);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const timestamp = start.getTime();
        setStartTimestamp(timestamp);
        localStorage.setItem('startTimestamp', timestamp.toString());
      }

      updateWorkedSeconds();

      interval = setInterval(() => {
        const now = Date.now();

        const totalWorkedSeconds = Math.floor((now - startTimestamp) / 1000);

        if (!isPaused && !pauseCompleted && totalWorkedSeconds >= 6 * 3600) {
          const pauseStart = startTimestamp + 6 * 3600 * 1000;
          setIsPaused(true);
          setPauseStartTime(pauseStart);
          localStorage.setItem('isPaused', 'true');
          localStorage.setItem('pauseStartTime', pauseStart.toString());

          setWorkedSeconds(6 * 3600);
        }

        if (isPaused) {
          const pauseElapsedSeconds = Math.floor((now - pauseStartTime) / 1000);

          if (pauseElapsedSeconds >= 30 * 60) {
            setIsPaused(false);
            setPauseTimeLeft(0);
            setPauseCompleted(true);
            localStorage.setItem('isPaused', 'false');
            localStorage.setItem('pauseCompleted', 'true');
          } else {
            setPauseTimeLeft(30 * 60 - pauseElapsedSeconds);
          }
        }

        updateWorkedSeconds();
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    isTracking,
    startTime,
    isPaused,
    pauseStartTime,
    startTimestamp,
    pauseCompleted,
    updateWorkedSeconds,
  ]);

  const handleStart = () => {
    if (startTime) {
      localStorage.setItem('startTime', startTime);
      setIsTracking(true);

      const start = new Date();
      const [hours, minutes] = startTime.split(':');
      start.setHours(hours);
      start.setMinutes(minutes);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const timestamp = start.getTime();
      setStartTimestamp(timestamp);
      localStorage.setItem('startTimestamp', timestamp.toString());
    }
  };

  const handleReset = () => {
    setStartTime('');
    setIsTracking(false);
    setWorkDuration('00:00:00');
    setBalance(0);
    setIsPaused(false);
    setPauseStartTime(null);
    setPauseTimeLeft(0);
    setWorkedSeconds(0);
    setStartTimestamp(null);
    setPauseCompleted(false);
    localStorage.removeItem('startTime');
    localStorage.removeItem('isPaused');
    localStorage.removeItem('pauseStartTime');
    localStorage.removeItem('startTimestamp');
    localStorage.removeItem('pauseCompleted');
  };

  const handleCheckout = () => {
    const endTime = format(new Date(), 'HH:mm');
    const newLog = {
      date: format(new Date(), 'yyyy-MM-dd'),
      start_time: startTime,
      end_time: endTime,
      duration: workDuration,
      balance: parseFloat(balance.toFixed(2)),
      schoolday: schoolday,
    };

    axios
      .post(
        'https://time.nohello.biz/api/logs',
        newLog,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      )
      .then(() => {
        onFetchLogs();
        handleReset();
        setSuccess('Log erfolgreich gespeichert');
      })
      .catch((error) => {
        console.error('Fehler beim Speichern des Logs:', error);
        setError('Fehler beim Speichern des Logs');
      });
  };

  const formatPauseTimeLeft = (pauseTimeLeftInSeconds) => {
    const minutes = Math.floor(pauseTimeLeftInSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (pauseTimeLeftInSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Paper sx={{ p: 4, maxWidth: 600, width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Timer
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            type="time"
            label="Startzeit (HH:MM)"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            required
            disabled={isTracking}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={schoolday}
              onChange={(e) => setSchoolday(e.target.checked)}
              color="primary"
            />
          }
          label="Schultag"
        />
        <Box sx={{ mt: 2 }}>
          {!isTracking ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleStart}
              disabled={!startTime}
            >
              Start
            </Button>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="outlined" color="secondary" fullWidth onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="success" fullWidth onClick={handleCheckout}>
                  Checkout
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
        {isTracking && (
          <Card sx={{ mt: 4, backgroundColor: '#f5f5f5' }}>
            <CardContent>
              {isPaused ? (
                <>
                  <Typography variant="h6" align="center" color="textSecondary">
                    Noch {formatPauseTimeLeft(pauseTimeLeft)} Minuten Pause
                  </Typography>
                  <Typography variant="h6" align="center" color="textSecondary">
                    Arbeitszeit
                  </Typography>
                  <Typography variant="h3" align="center" color="textSecondary">
                    {workDuration}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" align="center">
                    Arbeitszeit
                  </Typography>
                  <Typography variant="h3" align="center">
                    {workDuration}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      color: balance < 0 ? 'red' : 'green',
                      mt: 2,
                    }}
                  >
                    {balance < 0
                      ? `Minusstunden: -${Math.abs(balance).toFixed(2)}`
                      : `Plustunden: +${balance.toFixed(2)}`} Stunden
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        )}
      </Paper>
    </Box>
  );
};

export default TimerComponent;
