// EditUserLogs.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  Typography,
  Box,
} from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';

const EditUserLogs = ({ open, handleClose, log, setError, setSuccess, refreshLogs }) => {
  const [editedLog, setEditedLog] = useState({ ...log });
  const { user } = useContext(UserContext);

  useEffect(() => {
    setEditedLog({ ...log });
  }, [log]);

  const handleSave = () => {
    const { id, user_id, date, start_time, end_time, duration, balance, schoolday } = editedLog;

    if (!date || !start_time || !end_time || isNaN(duration) || isNaN(balance)) {
      setError('Bitte gültige Daten eingeben');
      return;
    }
    if (duration < 0 || balance < 0) {
      setError('Dauer und Balance dürfen nicht negativ sein');
      return;
    }

    axios
      .put(`https://time.nohello.biz/api/admin/users/${user_id}/logs/${id}`, {
        date,
        start_time,
        end_time,
        duration,
        balance,
        schoolday,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setSuccess('Log erfolgreich aktualisiert');
        handleClose();
        refreshLogs();
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren des Logs:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setError(`Fehler beim Aktualisieren des Logs: ${error.response.data.error}`);
        } else {
          setError('Fehler beim Aktualisieren des Logs');
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Log bearbeiten</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Datum"
            type="date"
            value={editedLog.date || ''}
            onChange={(e) =>
              setEditedLog({ ...editedLog, date: e.target.value })
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Startzeit"
            type="time"
            value={editedLog.start_time || ''}
            onChange={(e) =>
              setEditedLog({ ...editedLog, start_time: e.target.value })
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Endzeit"
            type="time"
            value={editedLog.end_time || ''}
            onChange={(e) =>
              setEditedLog({ ...editedLog, end_time: e.target.value })
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Dauer (Stunden)"
            type="number"
            value={editedLog.duration || ''}
            onChange={(e) =>
              setEditedLog({ ...editedLog, duration: parseFloat(e.target.value) })
            }
            fullWidth
            required
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Balance (Stunden)"
            type="number"
            value={editedLog.balance || ''}
            onChange={(e) =>
              setEditedLog({ ...editedLog, balance: parseFloat(e.target.value) })
            }
            fullWidth
            required
          />
        </Box>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={editedLog.schoolday || false}
            onChange={(e) =>
              setEditedLog({ ...editedLog, schoolday: e.target.checked })
            }
            color="primary"
          />
          <Typography component="span">Schultag</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserLogs;
